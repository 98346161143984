const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/cms";
const authUrl = "/auth";
const mailService = process.env.REACT_APP_API_URL + "/api/email";
const rentmanService = process.env.REACT_APP_API_URL + "/api/rentman";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  featuredProducts: dashboardUrl + "/products/featured",
  discounts: dashboardUrl + "/discounts/",
  promotions: dashboardUrl + "/discounts/promotions",
  discountCodes: dashboardUrl + "/discounts/discount-codes",
  requestsList: dashboardUrl + "/requests",
  equipmentList: dashboardUrl + "/equipment",
  customerList: dashboardUrl + "/customers",
  users: dashboardUrl + "/admin/users",
  reports: dashboardUrl + "/reports",
  order_invoice: mailService + "/order/invoice",
  invite_customer_mail: mailService + "/signup/invite",
  rentman_customers_all: rentmanService + "/customers/all",
  rentman_customers: rentmanService + "/customers",
  rentman_contacts: rentmanService + "/contacts",
  rentman_order: rentmanService + "/project_request",
  rentman_equipment: rentmanService + "/equipment/",
};
