// Tables
export const FIRESTORE_ORDERS_TABLE = "orders";
export const FIRESTORE_REQUESTS_TABLE = "requests";
export const FIRESTORE_PRODUCTS_TABLE = "products";
export const FIRESTORE_COMPANIES_TABLE = "companies";
export const FIRESTORE_EQUIPMENT_TABLE = "equipment";
export const FIRESTORE_PROMOTIONS_TABLE = "promotions";
export const FIRESTORE_USERS_TABLE = "users";
export const FIRESTORE_INVITED_USERS_TABLE = "invitedUsers";
export const FIRESTORE_DISCOUNT_CODES_TABLE = "discountCodes";
export const FIRESTORE_FEATURED_PRODUCTS_TABLE = "featuredProducts";

// Variables
export const FIRESTORE_PRODUCT_VARIATIONS_PRICE = "price";
export const FIRESTORE_DEFAULT_ORDERS_MAX_DAYS = 30;
