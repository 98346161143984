import { ApiOutlined, FileTextOutlined, LineChartOutlined, SolutionOutlined, UserOutlined } from "@ant-design/icons";
import i18n from "translations/i18n";
import urls from "urls";

const shopNavTree = [
  {
    key: "shop",
    title: i18n.t("b2b_shop"),
    breadcrumb: false,
    submenu: [
      {
        key: "shop-reports",
        path: urls.reports,
        title: i18n.t("reports"),
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-requests",
        path: urls.requestsList,
        title: i18n.t("requests"),
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-equipment",
        path: urls.equipmentList,
        title: i18n.t("items"),
        icon: ApiOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-customers",
        path: urls.customerList,
        title: i18n.t("customers"),
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const adminNavTree = [
  {
    isAdmin: true,
    key: "admin",
    title: i18n.t("admin"),
    breadcrumb: false,
    submenu: [
      {
        key: "admin-users",
        path: urls.users,
        title: i18n.t("users"),
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...shopNavTree, ...adminNavTree];

export default navigationConfig;
