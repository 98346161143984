import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${APP_PREFIX_PATH}/requests`} component={lazy(() => import(`./requests`))} />
        <Route path={`${APP_PREFIX_PATH}/equipment`} component={lazy(() => import(`./equipment`))} />
        <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />
        <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/requests`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
